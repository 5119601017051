import React from "react"
import classNames from "classnames"
import useSound from "use-sound"

import * as styles from "./utils/component.module.scss"
import clickSfx from "../../static/sounds/click_general.mp3"
import { isBrowser } from "../services/general"

const LinkButton = ({ image, label, link }) => {
    const [playClickSound] = useSound(clickSfx, {volume: 0.6})
    return(
        <button
        onClick={() => {
            if (isBrowser()) window.open(link, "_blank", "noopener")
        }}
            onMouseUp={playClickSound}
            className={classNames(styles["iconButton"])}
        >
            <div className="is-flex is-flex-direction-column is-align-items-center">
                <img
                    draggable={false}
                    src={image?.src}
                    alt={`${image.alt}`}
                    className={classNames(styles["iconImage"])}
                />
                <div className={classNames(styles["iconLabel"])}>
                    {label}
                </div>
            </div>
        </button>
    )
}

export default LinkButton