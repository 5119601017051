import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup";

import Window from "../Window"
import * as styles from "./utils/windows.module.scss"
import { handleSubmitEmail } from "../services/handleSubmitContact";
import FormErrorMessage from "../FormErrorMessage";

const ContactWindow = ({
    isOpen,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    activeWindow,
    setActiveWindow,
    setSuccessIsOpen
}) => {
    const [zIndex, setZIndex] = useState(0);
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        if (isOpen && activeWindow === "contact") {
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
        else if (!isOpen) {
            setZIndex(0)
        }
    }, [isOpen, activeWindow])

    return (
        <div className={`window ${isOpen ?  "is-open" : "is-closed"} contact`}
            style={{zIndex: `${zIndex}`}}>
            <Window
                title="contact"
                label="contact"
                draggable={true}
                setIsOpen={setIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                setActiveWindow={setActiveWindow}
                // customClassNames={hasError ? "shake" : ""}
            >
                <div className="p-6">
                    <span className={classNames(styles["about__contentHeader"])}>
                        WORK EMAIL{' '}
                    </span>
                    <span>
                        <a
                            className="has-text-primary"
                            href="mailto:hi@sharyap.com"
                            rel="noopener noreferrer">
                            hi@sharyap.com</a>
                    </span>

                    <div className="mt-5">
                        <span className={classNames(styles["about__contentHeader"])}>
                            QUICK CONTACT
                        </span>
                        
                        <Formik
                            initialValues={{
                                name: "",
                                email: "",
                                subject: "",
                                message: "",
                            }}
                            validationSchema={
                                Yup.object({
                                    name: Yup.string().required("This field is required"),
                                    email: Yup.string().email("Please input a valid email address").required("This field is required"),
                                    subject: Yup.string().required("This field is required"),
                                    message: Yup.string().required("This field is required"),
                                })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setHasError(false);
                                setTimeout(() => {
                                    handleSubmitEmail(values, setSuccessIsOpen);
                                    setSubmitting(false);
                                    resetForm();
                                }, 400);
                            }}
                        >
                            {({ isSubmitting }) => (
                            <Form>
                                <div className="columns is-flex">
                                    <div className="column mt-3">
                                        <Field
                                            type="text"
                                            name="name"
                                            className="input is-medium"
                                            placeholder="Your name"
                                            validate={setHasError(true)}
                                            style={{borderRadius: "15px"}}
                                        />
                                        <ErrorMessage name="name" component={FormErrorMessage} />

                                        <Field
                                            type="email"
                                            name="email"
                                            className="input is-medium mt-1"
                                            placeholder="Your email"
                                            validate={setHasError(true)}
                                            style={{borderRadius: "15px"}}
                                        />
                                        <ErrorMessage name="email" component={FormErrorMessage} />

                                        <Field
                                            type="text"
                                            name="subject"
                                            className="input is-medium mt-1"
                                            placeholder="Subject"
                                            validate={setHasError(true)}
                                            style={{borderRadius: "15px"}}
                                        />
                                        <ErrorMessage name="subject" component={FormErrorMessage} />
                                            
                                    </div>
                                    <div className="column is-flex is-flex-direction-column is-flex-grow-2 is-align-items-end mt-3">
                                        <Field
                                            as="textarea"
                                            name="message"
                                            className="textarea has-fixed-size is-medium is-rounded"
                                            placeholder="What's up?"
                                            validate={setHasError(true)}
                                            style={{borderRadius: "15px"}}
                                        />
                                        <ErrorMessage name="subject" component={FormErrorMessage} />

                                        <button
                                            className={classNames("button is-medium is-rounded is-primary mt-3",
                                                { "is-loading": isSubmitting }
                                            )}
                                            type="submit"
                                            disabled={isSubmitting}
                                            style={{ maxWidth: "115px", borderRadius: "15px"}}
                                            >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Window>
        </div>
    )
}

export default ContactWindow