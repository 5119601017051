import React, { useState, useRef, useContext } from "react";
import ReactHowler from "react-howler";

import bgm from "../../static/sounds/bgm.mp3"
import froggertPlay from "../../static/images/player/froggert_play.gif"
import froggertPlayDark from "../../static/images/player/froggert_play_dark.gif"
import froggertStop from "../../static/images/player/froggert_stop.gif"
import froggertStopDark from "../../static/images/player/froggert_stop_dark.gif"
import { AppContext } from "../context/AppContext";

const FroggertPlayer = () => {
    let { state } = useContext(AppContext)
    const [isPlaying, setIsPlaying] = useState(false);
    const player = useRef();

    const resetBgm = () => {
        player.current.stop();
        setIsPlaying(false)
    }

    return (
        <div className="is-clickable">
            {isPlaying ? 
                <button className="froggert"
                    onClick={() => {
                        resetBgm();
                    }}>
                    <img
                        src={state.isDark ? froggertPlayDark : froggertPlay}
                        alt="froggert play"
                        draggable={false}
                    />
                </button>
                :
                <button className="froggert"
                    onClick={() => setIsPlaying(true)}
                    >
                    <img
                        src={state.isDark ? froggertStopDark : froggertStop}
                        alt="froggert stop"
                        draggable={false}
                    />
                </button>
            }
            <ReactHowler
                src={bgm}
                loop={true}
                volume={0.3}
                ref={player}
                playing={isPlaying}
            />
        </div>
    )
}

export default FroggertPlayer