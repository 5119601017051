import React, { useContext } from "react"
import useSound from "use-sound"
import classNames from "classnames"

import * as styles from "./utils/component.module.scss"
import icon_downloadables from "../../static/images/icon_downloadables.png"
import icon_downloadables_dark from "../../static/images/icon_downloadables_dark.png"
import clickSfx from "../../static/sounds/click_general.mp3"
import { AppContext } from "../context/AppContext"

const DownloadsButton = ({ setIsOpen, setActiveWindow }) => {
    let { state } = useContext(AppContext)
    
    const [playClickSound] = useSound(clickSfx, {volume: 0.6})
    return(
        <button 
            onClick={() => {
                setIsOpen();
                setActiveWindow("downloadables")
            }}
            onMouseUp={playClickSound}
            className={classNames(styles["downloadsButton"], "is-flex is-align-items-center pr-2")}
        >
            <img
                className={classNames(styles["downloadsIcon"])}
                src={state.isDark ? icon_downloadables_dark : icon_downloadables}
                alt="downloads icon"
                draggable="false"
            />
            <div className={classNames(styles["downloadsText"])}>
                downloads
            </div>
        </button>
    )
}

export default DownloadsButton