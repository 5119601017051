// extracted by mini-css-extract-plugin
export var about__avatar = "windows-module--about__avatar--ada85";
export var about__content = "windows-module--about__content--8869e";
export var about__contentBody = "windows-module--about__content-body--f97a8";
export var about__contentHeader = "windows-module--about__content-header--455c7";
export var about__headerName = "windows-module--about__header-name--10e87";
export var button = "windows-module--button--f116e";
export var downloadables__card = "windows-module--downloadables__card--9a1d4";
export var downloadables__content = "windows-module--downloadables__content--138e0";
export var downloadables__contentHeader = "windows-module--downloadables__content-header--0e813";
export var downloadables__contentSubtitle = "windows-module--downloadables__content-subtitle--32880";
export var downloadables__tag = "windows-module--downloadables__tag--544cc";
export var faqs__content = "windows-module--faqs__content--a3e05";
export var remove = "windows-module--remove--bd40e";
export var sticker = "windows-module--sticker--5069a";
export var wallpaper = "windows-module--wallpaper--5713e";
export var work__content = "windows-module--work__content--9291e";
export var work__contentHeader = "windows-module--work__content-header--6d40c";
export var work__tag = "windows-module--work__tag--80a65";