import React, { useState, useEffect } from "react"
import classNames from "classnames"
import useSound from "use-sound"
import ReactPlayer from "react-player"

import Window from "../Window"
import * as styles from "./utils/windows.module.scss"

import tools from "./utils/tools.json"
import developments from "./utils/development.json"
import illustrations from "./utils/illustrations.json"
import animations from "./utils/animations.json"
import GalleryItem from "../GalleryItem"

import hoverSfx from "../../../static/sounds/hover.mp3"

const WorkWindow = ({
    isOpen,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    activeWindow,
    setActiveWindow
}) => {
    const [zIndex, setZIndex] = useState(0);

    useEffect(() => {
        if (isOpen && activeWindow === "work") {
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
        else if (!isOpen) {
            setZIndex(0)
        }
    }, [isOpen, activeWindow])

    const [playbackRate, setPlaybackRate] = useState(0.75);
    const [play] = useSound(hoverSfx, {
        playbackRate,
        volume: 0.1,
    });

    const handleHover = () => {
        if (playbackRate > 2) {
            setPlaybackRate(0.75)
        } else {
            setPlaybackRate(playbackRate + 0.1);
        }
        play();
    };

    return (
        <div className={`window ${isOpen ?  "is-open" : "is-closed"} work`}
            style={{zIndex: `${zIndex}`}}
            >
            <Window
                title="work"
                label="work"
                draggable={true}
                setIsOpen={setIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                setActiveWindow={setActiveWindow}
            >
                <div className={classNames("p-6", styles["work__content"])}>
                    <div className="has-background-light p-5 mb-5">
                        <b>Accepting work offers via my <a href="mailto:hi@sharyap.com" rel="noopener noreferrer">work email!</a></b>
                        <br/>I do illustration, animation, web design, and web/app development. {":)"}
                    </div>

                    {/* Tools and Development Section */}
                    <div className="columns">
                        <div className="column">
                            <div className={classNames(styles["work__contentHeader"])}>
                                TOOLS
                            </div>
                            <div className="is-flex is-flex-wrap-wrap">
                                {tools.map((tool) => (
                                    <div key={tool} className={classNames(styles["work__tag"])}
                                        onMouseEnter={handleHover}
                                    >
                                        {tool}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="column">
                            <div className={classNames(styles["work__contentHeader"])}>
                                DEVELOPMENT
                            </div>
                            <div className="is-flex is-flex-wrap-wrap">
                                {developments.map((development) => (
                                    <div key={development} className={classNames(styles["work__tag"])}
                                        onMouseEnter={handleHover}
                                    >
                                        {development}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr/>

                    {/* Illustrations */}
                    <div>
                        <div className={classNames(styles["work__contentHeader"])}>
                            ILLUSTRATION
                        </div>
                        <div>
                            <div className="is-flex">
                                {illustrations.slice(0,3).map((illustration) => (
                                    <GalleryItem key={illustration.name} image={illustration}/>
                                ))}
                            </div>
                            <div className="is-flex">
                                {illustrations.slice(3,6).map((illustration) => (
                                    <GalleryItem key={illustration.name} image={illustration}/>
                            ))}
                            </div>
                            <div className="is-flex">
                                {illustrations.slice(6,9).map((illustration) => (
                                    <GalleryItem key={illustration.name} image={illustration}/>
                            ))}
                            </div>
                        </div>
                        <div className="mt-2">
                            See more on{' '}
                            <a href="https://instagram.com/_yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                Instagram
                            </a>
                            {', '}
                            <a href="https://twitter.com/_yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                Twitter
                            </a>
                        </div>
                    </div>
                    <hr/>

                    {/* Animations */}
                    <div className="mt-6">
                        <div className={classNames(styles["work__contentHeader"])}>
                            ANIMATION
                        </div>
                        <div>
                            <div className={classNames("is-flex is-flex-wrap-wrap")}>
                                {animations.slice(0,4).map((animation) => (
                                    <div key={animation.name} className="mr-5 mt-4">
                                        <ReactPlayer
                                            url={animation.url}
                                            width="416px"
                                            height="234px"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-2">
                            See more on{' '}
                            <a href="https://youtube.com/c/sharyap"
                            target="_blank" rel="noopener noreferrer">
                                YouTube
                            </a>
                            {', '}
                            <a href="https://twitter.com/_yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                Twitter
                            </a>
                        </div>
                    </div>
                    <hr/>

                    {/* Development */}
                    <div className="mt-6">
                        <div className={classNames(styles["work__contentHeader"])}>
                            DEVELOPMENT
                        </div>
                        <div className={classNames(styles["about__contentBody"], "content")}>
                            <ul>
                                <li>This site!</li>
                                <li>This{' '}
                                    <a href="https://bingusfan25.itch.io/bingusadventure"
                                    target="_blank" rel="noopener noreferrer">
                                        buggy game jam
                                    </a>    
                                {' '}I made with a friend!</li>
                            </ul>
                        </div>
                        <div className="mt-2">
                            See more on{' '}
                            <a href="https://github.com/yapsharlene"
                            target="_blank" rel="noopener noreferrer">
                                GitHub
                            </a>
                        </div>
                    </div>
                </div>
            </Window>
        </div>
    )
}

export default WorkWindow