import React from "react"

import HomePage from "../MobilePages/HomePage"

const HomeMobile = () => {
  return (
    <HomePage />
  )
}

export default HomeMobile