import React, { useContext } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import * as styles from "./windows/utils/windows.module.scss"

import FooterLink from "./FooterLink"
import icons from "./utils/downloadablesIcons.json"
import { AppContext } from "../context/AppContext"

const DownloadablesCard = ({ downloadable }) => {
    let { state } = useContext(AppContext)
    return (
        <div className={classNames(styles["downloadables__card"], "columns m-2")}>
            <div>
                <img
                    src={downloadable?.thumbnail}
                    alt="thumbnail"
                    className="image is-128x128 mr-6"
                    style={{borderRadius: "15px"}}
                    draggable={false}
                />
            </div>
            <div>
                <div className="is-flex is-flex-direction-row">
                    <div className={classNames(styles["downloadables__contentHeader"],
                        "mr-3")}>
                        {downloadable.name}
                    </div>
                    <div className={classNames(styles["downloadables__tag"],
                    `${downloadable.type === "Stickers" ? styles["sticker"] : ""}`,
                    `${downloadable.type === "Wallpaper" ? styles["wallpaper"] : ""}`,
                    )}>
                        {downloadable.type}
                    </div>
                </div>
                <div className={classNames(styles["downloadables__contentSubtitle"])}
                    dangerouslySetInnerHTML={{
                        __html: downloadable.description
                    }}
                />
                
                <ReactTooltip/>
                <div className="pt-2 is-flex">
                    {Object.keys(downloadable.links).map((link) => (
                        <p key={link} data-tip={link}>
                            <FooterLink
                                image={{
                                    src: state.isDark ?  icons[`${link}_dark`] : icons[link],
                                    alt: icons[link]
                                }}
                                link={downloadable.links[link]}
                            />
                        </p>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DownloadablesCard