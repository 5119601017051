import React, { useEffect, useState } from "react"
import classNames from "classnames"
import useSound from "use-sound"

import Window from "../Window"
import downloadables from "./utils/downloadables.json"
import DownloadablesCard from "../DownloadablesCard"
import * as styles from "./utils/windows.module.scss"
import clickSfx from "../../../static/sounds/click_filter.mp3"

const DownloadablesWindow = ({
    isOpen,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    activeWindow,
    setActiveWindow
}) => {
    const [zIndex, setZIndex] = useState(0);
    const [filter, setFilter] = useState("none");
    const [playClickSfx] = useSound(clickSfx, {volume: 0.5})

    const filterItems = (filter) => {
        if (filter === "none") return downloadables
        return downloadables.filter((downloadable) => downloadable.type === filter);
    }

    let filteredDownloadables = filterItems(filter);

    useEffect(() => {
        if (isOpen && activeWindow === "downloadables") {
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
        else if (!isOpen) {
            setZIndex(0)
        }
    }, [isOpen, activeWindow])

    useEffect(() => {
        filteredDownloadables = filterItems(filter);
    }, [filter])

    return (
        <div className={`window ${isOpen ?  "is-open" : "is-closed"} downloadables`}
            style={{zIndex: `${zIndex}`}}>
            <Window
                title="downloadables"
                label="downloadables"
                draggable={true}
                setIsOpen={setIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                setActiveWindow={setActiveWindow}
            >
                <div className={classNames("p-6", styles["downloadables__content"])}>
                    <div className="has-background-light p-5 mb-5">
                        The following are some downloadable content you can grab from me! :)<br/>
                        All content is <b>free for personal use</b> only.
                    </div>

                    <div className="is-flex is-align-items-center pb-3 pl-3">
                        <p className="pr-2">Filter by: </p>
                        <button className={classNames(styles["downloadables__tag"],
                            styles["sticker"], styles["button"])}
                            onClick={() => setFilter("Stickers")}
                            onMouseUp={() => playClickSfx()}>
                            Stickers
                        </button>
                        <button className={classNames(styles["downloadables__tag"],
                            styles["wallpaper"], styles["button"])}
                            onClick={() => setFilter("Wallpaper")}
                            onMouseUp={() => playClickSfx()}>
                            Wallpaper
                        </button>
                        {filter !== "none" ? 
                            <button className={classNames(styles["downloadables__tag"],
                                styles["remove"], styles["button"])}
                                onClick={() => setFilter("none")}
                                onMouseUp={() => playClickSfx()}>
                                X
                            </button>
                            : null
                        }
                    </div>

                    {filteredDownloadables.map((downloadable) => (
                        <DownloadablesCard
                            key={downloadable.name}
                            downloadable={downloadable}
                        />
                    ))}
                    
                </div>
            </Window>
        </div>
    )
}

export default DownloadablesWindow