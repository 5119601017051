import React, { useContext } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import IconButton from "../IconButton"

import icons from "./utils/homeWindowIcons.json"
import { AppContext } from "../../context/AppContext"

import * as styles from "../utils/component.module.scss"

const HomePage = () => {
    let { state } = useContext(AppContext)

    return (
        <div className="is-flex is-flex-direction-column is-align-items-center">
            <h1 className="big-title mobile has-text-centered">
                hi! <span className="has-text-primary has-text-weight-black">i’m shar</span>
            </h1>

            <h3 className="big-subtitle has-text-centered">
                illustrator, animator, and developer
            </h3>

            <div className="mobile-icons-container is-flex is-flex-wrap-wrap is-justify-content-center">
                {icons.map((icon) => (
                    <Link to={icon.link} key={icon.label} style={{ textDecoration: "none" }}>
                        <div className="icon-button-box">
                            <IconButton
                                image={{
                                    src: state.isDark ? icon.image_dark : icon.image,
                                    alt: icon.label
                                }}
                                label={icon.label}
                            />
                        </div>
                        <div className={classNames(styles["iconLabel"])}>
                            {icon.label}
                        </div>
                    </Link>
            ))}
            </div>
        </div>
    )
}
export default HomePage