import React from "react"
import classNames from "classnames"
import useSound from "use-sound"

import * as styles from "./utils/component.module.scss"
import clickSfx from "../../static/sounds/click_general.mp3"

const IconButton = ({ image, label, setIsOpen, setActiveWindow }) => {
    const [playClickSound] = useSound(clickSfx, {volume: 0.2})
    return(
        <button
            onClick={() => {
                setIsOpen && setIsOpen();
                setActiveWindow && setActiveWindow(label)
            }}
            onMouseUp={playClickSound}
            className={classNames(styles["iconButton"])}
        >
            <div className="is-flex is-flex-direction-column is-align-items-center">
                <img
                    draggable={false}
                    src={image?.src}
                    alt={`${image.alt}`}
                    className={classNames(styles["iconImage"])}
                />
                {setIsOpen && <div className={classNames(styles["iconLabel"])}>
                    {label}
                </div>}
            </div>
        </button>
    )
}

export default IconButton