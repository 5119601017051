import React, { useContext } from "react"
import "../../styles/global.scss"

import Window from "../Window"
import DownloadsButton from "../DownloadsButton"
import IconButton from "../IconButton"
import { AppContext } from "../../context/AppContext"

import icons from "./utils/homeWindowIcons.json"

const getHook = (hooks, label) => {
    let iconHook = hooks.filter(hook => hook.window === label)
    return iconHook[0].hook
}

const HomeWindow = ({ hooks, setActiveWindow }) => {
    let { state } = useContext(AppContext)

    return (
        <Window title="home" draggable={false}>
            <div className="p-4">
                <DownloadsButton
                    setIsOpen={getHook(hooks, "downloadables")}
                    setActiveWindow={setActiveWindow}
                />
            </div>
            <h1 className="big-title has-text-centered">
                hi! <span className="has-text-primary has-text-weight-black">i’m shar</span>
            </h1>

            <h3 className="big-subtitle has-text-centered">
                illustrator, animator, and developer
            </h3>

            <div className="icons-container is-flex is-flex-wrap-wrap
                is-flex-direction-row is-justify-content-center">
                {icons.map((icon) => (
                    <div key={icon.label} className="m-4">
                        <IconButton
                            image={{
                                src: state.isDark ? icon.image_dark : icon.image,
                                alt: icon.label
                            }}
                            label={icon.label}
                            setIsOpen={getHook(hooks, icon.label)}
                            setActiveWindow={setActiveWindow}
                        />
                    </div>
            ))}
            </div>
      </Window>
    )
}

export default HomeWindow