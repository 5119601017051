import React, { useContext } from "react"
import classNames from "classnames"

import FooterLink from "./FooterLink"
import FroggertPlayer from "./FroggertPlayer"

import footerLinks from "../components/utils/footerLinks.json"
import { AppContext } from "../context/AppContext"
import * as styles from "./utils/component.module.scss" 
import MadeWithBulma from "./MadeWithBulma"

const Footer = () => {
    let { state } = useContext(AppContext)

    return (
        <footer className="footer-bar is-flex-direction-column">
            <div className="froggert-player is-flex is-justify-content-flex-end">
                <FroggertPlayer/>
            </div>

            <div className="footer-custom is-hidden-mobile is-flex is-justify-content-center">
            {footerLinks.map((link) => (
                <div key={link.label}>
                <FooterLink
                    image={{
                    src: state.isDark ? link.image_dark : link.image,
                        alt: link.label
                    }}
                    link={link.link}
                    />
                </div>
            ))}
            </div>
            <div className={classNames(styles["footer__copyright"],
                "is-size-7 has-text-centered"
                )}>
                © 2023 Sharlene Yap
                {/* <MadeWithBulma className="is-hidden-tablet" isMobile /> */}
            </div>
            {/* <MadeWithBulma className="community is-hidden-mobile" /> */}
        </footer>
    )
}

export default Footer