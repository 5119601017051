import React, { useEffect, useState } from "react"
import Window from "../Window"
import classNames from "classnames"

import avatarImage from "../../../static/images/avatar.png"
import { LanguageProficiency } from "./utils/languageProficiency"
import * as styles from "./utils/windows.module.scss"

const AboutWindow = ({
    isOpen,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    activeWindow,
    setActiveWindow
}) => {

    const [zIndex, setZIndex] = useState(0);

    useEffect(() => {
        if (isOpen && activeWindow === "about") {
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
        else if (!isOpen) {
            setZIndex(0)
        }
    }, [isOpen, activeWindow])

    return (
        <div className={`window ${isOpen ?  "is-open" : "is-closed"}`}
            style={{zIndex: `${zIndex}`}}>
            <Window
                title="about"
                label="about"
                draggable={true}
                setIsOpen={setIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                setActiveWindow={setActiveWindow}
            >
                <div className="pt-6 p-5 is-flex is-align-items-center">
                    <figure className={classNames("has-text-primary image pl-5", styles["about__avatar"])}>
                        <img
                            src={avatarImage}
                            alt="avatar"
                            className="is-rounded"
                        />
                    </figure>
                    <div className="pl-6">
                        <div className={classNames(styles["about__headerName"])}>
                            Sharlene Yap <span className="is-size-4 has-text-weight-normal">葉千林</span>
                        </div>
                        <div className="is-size-5">
                            PH-based freelance illustrator, animator<br/>
                            Former web developer at <a href="https://medgrocer.com/" target="_blank" rel="noopener noreferrer">MedGrocer</a>
                        </div>
                    </div>
                </div>
                <hr className="mb-0"/>
                <div className={classNames("pl-6 pr-6", styles["about__content"])}>
                    <div className="is-size-5 pt-4">
                        <div className="pb-3">hi! i'm shar, an animator and web developer. i...</div>
                        <div className="content">
                            <ul className="mt-2">
                                <li>create <a href="https://www.youtube.com/playlist?list=PL1xe7Vhuzrm-oEZUi0DO2Wu1pgUzMDfHN/" target="_blank" rel="noopener noreferrer">hand-drawn animations</a>,</li>
                                <li>create <a href="https://www.youtube.com/playlist?list=PL1xe7Vhuzrm8RwrchXW-o08u9Tq8gjzsW" target="_blank" rel="noopener noreferrer">videos</a> talking about things i like,</li>
                                <li>create website wireframes, and</li>
                                <li>do frontend web development!</li>
                            </ul>
                        </div>

                        <div className="pb-3">interested in working with me? send me an email at <a href="mailto:hi@sharyap.com" rel="noopener noreferrer">hi@sharyap.com</a>! :)</div>

                    </div>
                    <br/>
                    <span className={classNames(styles["about__contentHeader"])}>
                        EDUCATION
                    </span>
                    <br/>
                    <div className={classNames(styles["about__contentBody"])}
                        style={{ lineHeight: "20px" }}>
                            Bachelor of Science in Computer Science
                        <br/>
                        <span className="is-size-7">
                            (GRADUATED CUM LAUDE 2023)
                        </span>
                    </div>
                    <br/>

                    <span className={classNames(styles["about__contentHeader"])}>
                        OTHER INTERESTS
                    </span>
                    <br/>
                    <div className="content">
                        <ul className="mt-2">
                            <li>Game development</li>
                            <li>Music! Singing, guitar, piano, ocarina...</li>
                            <li>Legend of Zelda franchise</li>
                            <li>HoYoVerse Games (Genshin Impact, Tears of Themis, etc.)</li>
                            <li>Indie games with forest themes :)</li>
                        </ul>
                    </div>
                    <br/>
                    
                    <LanguageProficiency styles={styles}/>
                    <br/><br/>
                </div>
            </Window>
        </div>
    )
}

export default AboutWindow