import React, { Fragment, useContext } from "react"

import ThemeToggle from "../components/ThemeToggle"
import HomeDesktop from "../components/Home/HomeDesktop"
import HomeMobile from "../components/Home/HomeMobile"
import Footer from "../components/Footer"
import WaveBackground from "../components/WaveBackground"
import { AppContext } from "../context/AppContext"

const App = () => {
  let { state } = useContext(AppContext)
  return (
    <Fragment>
      <nav className="navbar is-transparent">
        <ThemeToggle />
      </nav>
      <div className="is-hidden-mobile">
        <HomeDesktop/>
      </div>
      <div className="is-hidden-tablet">
        <HomeMobile />
      </div>
      <Footer />
      <div className="bg">
          <WaveBackground state={state} />
      </div>
    </Fragment>
  )
}

export default App

export const Head = () => <title>shar's desktop</title>
