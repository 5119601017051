import React, { useEffect, useState, useContext } from "react"
import useSound from "use-sound"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

import Window from "../Window"
import openSound from "../../../static/sounds/hover.mp3"

const SuccessWindow = ({
    isOpen,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    activeWindow,
    setActiveWindow
}) => {
    const [zIndex, setZIndex] = useState(0);

    const [playOpenSound] = useSound(openSound, {volume: 0.5})

    useEffect(() => {
        if (isOpen) {
            playOpenSound()
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
    }, [isOpen])

    useEffect(() => {
        if (isOpen && activeWindow === "success") {
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
        else if (!isOpen) {
            setZIndex(0)
        }
    }, [isOpen, activeWindow])

    return (
        <div className={`window ${isOpen ?  "is-open" : "is-closed"} success`}
            style={{zIndex: `${zIndex}`}}>
            <Window
                title="success"
                label="success!"
                draggable={true}
                setIsOpen={setIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                setActiveWindow={setActiveWindow}
            >
                <div className="p-5 is-flex is-justify-content-center is-flex-direction-column has-text-centered">
                    <FontAwesomeIcon icon={faHeart} className="my-2" size="3x"/>
                    Your message has been sent!
                </div>
            </Window>
        </div>
    )
}

export default SuccessWindow