import React, { useState } from "react"
import classNames from "classnames"
import useSound from "use-sound"
import Draggable from "react-draggable"
import { isBrowser } from "../services/general"

import * as styles from "./utils/component.module.scss"
import exitSfx from "../../static/sounds/click_close.mp3"


const Window = ({
    customClassNames,
    title,
    label,
    children,
    draggable,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    setActiveWindow
}) => {
    const [dragging, setDragging] = useState(false);
    const [playExitSound] = useSound(exitSfx, {volume: 0.3});
    
    const getWindowSize = () => {
        if (isBrowser()) return { width: window.innerWidth, height: window.innerHeight }
    }

    const DEFAULT_POSITION = { x: 0, y: 0 };
    const CENTER = isBrowser() ? { x: getWindowSize().width / 5, y: getWindowSize().height / 6 } : DEFAULT_POSITION;

    return (
        <Draggable
            handle=".handle"
            onStart={() => draggable}
            onDrag={() => {
                setDragging(true);
                setActiveWindow(title);
            }}
            onStop={() => setDragging(false)}
            bounds=".bounds"
            cancel=".is-x"
            defaultPosition={draggable ? CENTER : DEFAULT_POSITION}
            // positionOffset={draggable ? {x: "300px", y: "300px"} : null}
        >
            <div className={classNames(
                `card no-cursor is-flex is-flex-direction-column
                ${draggable ? "draggable" : ""}
                ${draggable && dragging ? "is-dragging" : ""}
                ${title === "links" ? styles["window__links"] : ""}
                ${title === "contact" ? styles["window__contact"] : ""}
                ${title === "success" ? styles["window__success"] : ""}`,
                styles["window"])}
                >
                <div className={`card-header handle is-shadowless p-2
                    ${draggable? "cursor" : ""}
                    ${draggable && dragging ? "cursor_grabbing" : ""}
                    ${title === "success" ? styles["window__successHeader"] : ""}    
                `}>
                    <h3 className={classNames(
                        "is-size-4",
                        styles["window__titleText"]
                        )}>
                        {label}
                    </h3>

                    <button
                        className={classNames(
                        `is-x is-size-4 ${draggable ? "" : "is-invisible"}
                        ${title === "success" ? styles["window__successX"] : ""}`,
                        styles["window__titleX"]
                        )}
                        onClick={() => {
                            setIsOpen(false);
                            setHighestZIndex(highestZIndex - 1);
                        }}
                        onMouseUp={playExitSound}
                        >
                        [x]
                    </button>
                </div>
                <div className="card-content p-0">
                    {children}
                </div>
            </div>
        </Draggable>
    )
}

export default Window