import React, { Fragment, useState } from "react"

import HomeWindow from "../Windows/HomeWindow"
import AboutWindow from "../Windows/AboutWindow"
import LinksWindow from "../Windows/LinksWindow"
import WorkWindow from "../Windows/WorkWindow"
import FaqWindow from "../Windows/FaqWindow"
import ContactWindow from "../Windows/ContactWindow"
import DownloadablesWindow from "../Windows/DownloadablesWindow"
import SuccessWindow from "../Windows/SuccessWindow"


const HomeDesktop = () => {
    const [highestZIndex, setHighestZIndex] = useState(2);
    const [aboutIsOpen, setAboutIsOpen] = useState(false);
    const [linksIsOpen, setLinksIsOpen] = useState(false);
    const [workIsOpen, setWorkIsOpen] = useState(false);
    const [faqIsOpen, setFaqIsOpen] = useState(false);
    const [contactIsOpen, setContactIsOpen] = useState(false);
    const [downloadablesIsOpen, setDownloadablesIsOpen] = useState(false);
    const [successIsOpen, setSuccessIsOpen] = useState(false);

    const [activeWindow, setActiveWindow] = useState("");

    const setOpenHooks = [
        {
        window: "about",
        hook() {
            setAboutIsOpen(true)
        }
        },
        {
        window: "links",
        hook() {
            setLinksIsOpen(true)
        }
        },
        {
        window: "work",
        hook() {
            setWorkIsOpen(true)
        }
        },
        {
        window: "faq",
        hook() {
            setFaqIsOpen(true)
        }
        },
        {
        window: "contact",
        hook() {
            setContactIsOpen(true)
        }
        },
        {
        window: "downloadables",
        hook() {
            setDownloadablesIsOpen(true)
        }
        },
        {
        window: "success",
        hook() {
            setSuccessIsOpen(true)
        }
        }
    ]

    return (
        <Fragment>
            <div className="home">
                <HomeWindow
                    hooks={setOpenHooks}
                    setActiveWindow={setActiveWindow}
                />
            </div>

            <div className="bounds">
                <AboutWindow
                isOpen={aboutIsOpen}
                setIsOpen={setAboutIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                />

                <LinksWindow
                isOpen={linksIsOpen}
                setIsOpen={setLinksIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                />

                <WorkWindow
                isOpen={workIsOpen}
                setIsOpen={setWorkIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                />

                <FaqWindow
                isOpen={faqIsOpen}
                setIsOpen={setFaqIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                />

                <ContactWindow
                isOpen={contactIsOpen}
                setIsOpen={setContactIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                setSuccessIsOpen={setSuccessIsOpen}
                />

                <DownloadablesWindow
                isOpen={downloadablesIsOpen}
                setIsOpen={setDownloadablesIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                />

                <SuccessWindow
                isOpen={successIsOpen}
                setIsOpen={setSuccessIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                />
            </div>
        </Fragment>
    )
}

export default HomeDesktop