import React, { useEffect, useState, useContext } from "react"

import Window from "../Window"
import links from "./utils/linksList.json"
import LinkButton from "../LinkButton"
import { AppContext } from "../../context/AppContext"

const LinksWindow = ({
    isOpen,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    activeWindow,
    setActiveWindow
}) => {
    let { state } = useContext(AppContext)

    const [zIndex, setZIndex] = useState(0);

    useEffect(() => {
        if (isOpen && activeWindow === "links") {
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
        else if (!isOpen) {
            setZIndex(0)
        }
    }, [isOpen, activeWindow])

    return (
        <div className={`window ${isOpen ?  "is-open" : "is-closed"} links`}
            style={{zIndex: `${zIndex}`}}>
            <Window
                title="links"
                label="links"
                draggable={true}
                setIsOpen={setIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                setActiveWindow={setActiveWindow}
            >
                <div className="mt-5 is-flex is-justify-content-center">
                    {links.slice(0,4).map((link) => (
                        <div key={link.label} className="m-5">
                            <LinkButton
                                image={{
                                    src: state.isDark ? link.image_dark : link.image,
                                    alt: link.label
                                }}
                                label={link.label}
                                link={link.link}
                            />
                        </div>
                    ))}
                </div>
                <div className="mt-5 is-flex is-justify-content-center">
                    {links.slice(4,8).map((link) => (
                        <div key={link.label} className="m-5">
                            <LinkButton
                                image={{
                                    src: state.isDark ? link.image_dark : link.image,
                                    alt: link.label
                                }}
                                label={link.label}
                                link={link.link}
                            />
                        </div>
                    ))}
                </div>
            </Window>
        </div>
    )
}

export default LinksWindow