// extracted by mini-css-extract-plugin
export var about__avatar = "windows-module--about__avatar--8f559";
export var about__content = "windows-module--about__content--e1f52";
export var about__contentBody = "windows-module--about__content-body--e0f5e";
export var about__contentHeader = "windows-module--about__content-header--d7f3a";
export var about__headerName = "windows-module--about__header-name--84143";
export var button = "windows-module--button--3a60e";
export var downloadables__card = "windows-module--downloadables__card--655bf";
export var downloadables__content = "windows-module--downloadables__content--04212";
export var downloadables__contentHeader = "windows-module--downloadables__content-header--c6f6a";
export var downloadables__contentSubtitle = "windows-module--downloadables__content-subtitle--3918a";
export var downloadables__tag = "windows-module--downloadables__tag--bf2bc";
export var faqs__content = "windows-module--faqs__content--71c9d";
export var remove = "windows-module--remove--b0fef";
export var sticker = "windows-module--sticker--e10b6";
export var wallpaper = "windows-module--wallpaper--c995c";
export var work__content = "windows-module--work__content--c0658";
export var work__contentHeader = "windows-module--work__content-header--c5a57";
export var work__tag = "windows-module--work__tag--4164d";