import React, { useContext } from 'react'
import useSound from 'use-sound'

import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { AppContext } from '../context/AppContext'

import darkMode from "../../static/images/dark_mode_dark.png"
import lightMode from "../../static/images/dark_mode_light.png"
import darkModeSfx from "../../static/sounds/darkmode.mp3"
import lightModeSfx from "../../static/sounds/lightmode.mp3"

const ThemeToggle = () => {
  let { state, dispatch } = useContext(AppContext);
  const [playLightModeSfx] = useSound(lightModeSfx, {volume: 0.2});
  const [playDarkModeSfx] = useSound(darkModeSfx, {volume: 0.2});

  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => {
        if (theme == null) {
          return null
        }
        return (
          <button
            className="theme-toggle"
            onClick={() => {
              toggleTheme(theme === "light" ? "dark" : "light");
              if (theme === "dark") {
                dispatch({type: "SET_DARK"})
                playLightModeSfx()
              }
              else {
                dispatch({type: "SET_DEFAULT"})
                playDarkModeSfx()
              }
            }}
          >
            <figure className='theme-toggle-icon'>
              <img
                src={ state.isDark ? darkMode : lightMode }
                alt="dark mode toggle"
              />
            </figure>
          </button>
      )}}
    </ThemeToggler>
  )
}

export default ThemeToggle