import React, { useEffect, useState } from "react"
import classNames from "classnames"

import Window from "../Window"
import CustomCollapsible from "./Collapsible"

import faqs from "./utils/faqs.json"
import * as styles from "./utils/windows.module.scss"

const FaqWindow = ({
    isOpen,
    setIsOpen,
    highestZIndex,
    setHighestZIndex,
    activeWindow,
    setActiveWindow
}) => {
    const [zIndex, setZIndex] = useState(0);

    useEffect(() => {
        if (isOpen && activeWindow === "faq") {
            setHighestZIndex(highestZIndex + 1);
            setZIndex(highestZIndex);
        }
        else if (!isOpen) {
            setZIndex(0)
        }
    }, [isOpen, activeWindow])

    return (
        <div className={`window ${isOpen ?  "is-open" : "is-closed"} faq`}
            style={{zIndex: `${zIndex}`}}>
            <Window
                title="faq"
                label="frequently asked questions"
                draggable={true}
                setIsOpen={setIsOpen}
                highestZIndex={highestZIndex}
                setHighestZIndex={setHighestZIndex}
                setActiveWindow={setActiveWindow}
            >
                <div className={classNames("p-5", styles["faqs__content"])}>
                {faqs.map((faq) => (
                    <div key={faq.question} className="content">
                        <CustomCollapsible
                            title={faq.question}>
                            <p
                                dangerouslySetInnerHTML={
                                    {__html: faq.content}
                                }
                            />
                        </CustomCollapsible>
                        <hr/>
                    </div>
                    )
                )}
                </div>
            </Window>
        </div>
    )
}

export default FaqWindow